<template>
  <el-dialog class="operation_dialog" v-model="dialogVisible" :title="dialogtitle" :close-on-click-modal="false"
    width="960px">
    <!-- {{ state.imgs }} -->
    <div class="dp-f fw-w imgbox">
      <div class="imgitem imgshowitem dp-fc pt-r" v-for="(el, ind) in state.imgs" :key="ind">
        <img :src="el.showurl" style="max-width: 100%;max-height: 100%;">
        <div class="operation dp-f ai-c jc-sb pt-a">
          <el-upload class="w-24 h-24" :accept="img_accept" :show-file-list="false"
            :http-request="httpRequestImg">
            <img class="w-24 h-24 cu-p" src="@/assets/img/initiateContract/reset.png" @click="state.nowIndex=ind">
          </el-upload>
          <img class="w-24 h-24 cu-p" src="@/assets/img/initiateContract/detele2.png" @click="state.imgs.splice(ind, 1)">
        </div>
      </div>
      <el-upload :accept="img_accept" :show-file-list="false" :http-request="httpRequestImg">
        <div class="w100 h100 imgitem imgshowitem dp-fc mr0" @click="state.nowIndex=null">
          <div class="ta-c">
            <img class="w-56 h-56" src="@/assets/img/initiateContract/uploadimgs.png">
            <div class="w100 ta-c">上传图片</div>
          </div>
        </div>
      </el-upload>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2 @buttonclick="dialogloading = true,emit('Emit',state.imgs)"
          v-model:loading="dialogloading"></oabutton>
        <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3 @buttonclick="dialogVisible = false">
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits, watch } from 'vue'
import { handleMessage } from "@/utils/server/confirm";
import { img_accept } from "@/utils/base/accept.js";
import { getfileTypeName } from "@/utils/server/file.js"
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogtitle = ref();
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
  imgs: [],
  nowIndex:null
})

const show = ((imgurl) => {
  dialogtitle.value = '上传图片'
  state.imgs = []
  dialogVisible.value = true
})
// 图片文件上传
const httpRequestImg = (rawFile) => {
  // console.log('图片上传接口请求', rawFile)
  let fileTypeName = getfileTypeName(rawFile.file.name)
  let imgtype = ['gif', 'GIF', 'jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG']
  if (rawFile.file.size / 1024 / 1024 > 20) {
    handleMessage('图片大小超过20MB')
  } else if (imgtype.includes(fileTypeName)) {
    let URL = window.URL || window.webkitURL;
    let imgURL = URL.createObjectURL(rawFile.file);
    // console.log('state.nowIndex',state.nowIndex)
    if (state.nowIndex===null)
      state.imgs.push({ file:rawFile.file, showurl: imgURL,name:rawFile.file.name })
    else
      state.imgs[state.nowIndex] = { file:rawFile.file, showurl: imgURL,name:rawFile.file.name }
  } else {
    handleMessage('请上传正确图片格式!')
  }
}
const reset=()=>{
  dialogloading.value = false
  dialogVisible.value = false
}
defineExpose({
  show,reset
});
</script>
<style lang="scss" scoped>
.imgitem {
  width: 290px;
  margin-right: 15px;
  height: 348px;
}

.imgshowitem {
  padding: 10px;
  background: #F2F2F2;
  border: 1px solid #D5D5D5;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-bottom: 22px;
}

.mr0 {
  margin-right: 0;
}

.imgbox {
  :nth-child(3n).imgitem {
    margin-right: 0;
  }

  .operation {
    bottom: 0;
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.4);
    padding: 0 70px;
  }
}
</style>